

















import { version, link } from '@/version.json';

export default {
  name: 'GitLabLink',
  computed: {
    getLink(): string {
      if (!link) return '#';

      return link.replace('git@gitlab.com:', 'https://gitlab.com/');
    },
    getVersion(): string {
      if (!version) return undefined;

      return version;
    }
  }
};
